import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import PrePrimary from './PrePrimary';
import Class1 from './Class1';
import Class1Photos from './Class1Photos';
import Class2 from './Class2';
import Class2Photos from './Class2Photos';

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const EcoClub= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Box>

        <Container>
              <Form />
              <PrePrimary/>
        </Container>
        <Container>
              <Class1/>
              <Class1Photos />
        </Container>
        <Container>
          <Class2/>
          <Class2Photos/>
          
        </Container>
      </Box>
    </Main>
  );
};

export default EcoClub;
