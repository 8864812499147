/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



const Class2 = () => {

  return (
    <Box>
      <Box marginBottom={4}>
        
     
    <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
          }}
        >
           Conservation of Water
        </Typography>
        <Typography fontSize = "x-small">
        Class2 - 24 June 2022
        </Typography>
        <Typography 
        align='justify'
        component='p'
        
        >
<br />
The students of Class 2 of National Public School, Yeshwanthpur drew pictures on “Conservation of Water” with slogans as part of the Environment Day celebration.
<br/>
They shared their views on water pollution and suggested few ways to conserve water. They also emphasised on the importance of plants which help in getting more rain and pledged that they would be nature lovers and would plant more saplings. The students assured that they will not waste the resources that are obtained from plants like pencils and papers, thereby preserving nature.
<br/>
The children thoroughly enjoyed the activity and learnt more about the nature.



        </Typography>
        <Typography align='center' fontWeight={600}>
       <br/> “Small hands can change the world, be the one.”
        </Typography>
      </Box>
    </Box>
  );
};

export default Class2;
