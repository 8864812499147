/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



const Form = () => {

  return (
    <Box>
      <Box marginBottom={4}>
        
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          ECO CLUB
        </Typography>
        <br />  <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
          }}
        >
            NURTURE NATURE FOR A BETTER FUTURE!
        </Typography>
        <Typography fontSize = "x-small">
        Pre-Primary - 30 June 2022
        </Typography>
        <Typography 
        align='justify'
        component='p'
        
        >
<br />
          With this theme in perspective, the students and teachers of Pre-Primary at National Public School Yeshwanthpur participated and observed the significant occasion of Environment Day on 30 June 2022.<br />

In simple terms, our ‘NATURE’ is our ‘FUTURE’, this seed of awareness was sown into the young minds who eagerly participated in a variety of activities planned for the day. The students were involved in age-appropriate hands on- activities namely, sowing seeds, segregation of wet and dry waste and campaign through slogans on saving Our Earth. This experiential learning created awareness amongst students and in turn urged them to share and spread the message for a greener tomorrow. Students were encouraged to continue making their little contribution towards taking care and saving our nature.<br />

Conserving and taking care of our environment is a value and must become a virtue.

        </Typography>
        <Typography align='center' fontWeight={600}>
       <br/> Little steps towards saving nature…
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
