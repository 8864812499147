/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



const Class1 = () => {

  return (
    <Box>
      <Box marginBottom={4}>
        
      
        <br />  <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
          }}
        >
            Environment Day
        </Typography>
        <Typography fontSize = "x-small">
            class1- 17 June 2022
        </Typography>
        <Typography 
        align='justify'
        component='p'
        
        >
<br />
The students of Class 1 of National Public School, Yeshwanthpur observed World Environment Day on Friday, 17 June 2022. The students were shown a video on how to reduce the usage of plastic to save our planet Earth. The main objective of observing this day was to bring about a change in the attitude of the students towards the environment.  It advocated a partnership between each human being and the society to ensure a safe future. The students thoroughly enjoyed the activity.



        </Typography>
        <Typography align='center' fontWeight={600}>
       <br/> “Let’s nurture the nature so that we can have a better future.”
        </Typography>
      </Box>
    </Box>
  );
};

export default Class1;
